.LoginPage {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  min-height: 800px;

  .wrapper {
    flex: 1;
    > div {
      max-width: 312px;
      margin: auto; 
    }
  
    .header {
      margin: 0px auto 39px auto;
      max-width: 228px;
    }
  
    .footer {
      margin-top: 36px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;
  
      a {
        width: 100%;
  
        .ant-btn {
          height: 48px;
        }
      }
  
      p {
        margin: 0;
        font-size: 14px;
        font-weight: 350;
        line-height: 24px;
        color: #FFFFFF;
        text-decoration: none;
      }
    }
  }

  .copyright {
    max-height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    p, a {
      margin: 0;
      font-size: 14px;
      line-height: 24px;
    }

    p {
      color: #212121;
    }

    a {
      color: #69140E;
      text-decoration: none;
    }
  }
}