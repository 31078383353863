@import "./mixins.scss";

:root {
  --primary-color: #69140E;
  // --primary-color-opacity: #9863f3;
}

body {
  margin: 0;
  font-family: 'Avenir', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F4F4;
}

img {
  max-width: 100%;
}

#LayoutAuth {
  background: linear-gradient(180deg, #420000 0%, #69140E 40%, #8F4820 60%, #E5E2CF 100%);
  min-height: 100vh;
}

#MainLayout {
  background: linear-gradient(180deg, #420000 0%, #69140E 50.23%, #E5E2CF 100%);
  min-height: 100vh;
  position: relative;

  .Content {
    padding-top: 65px;
    padding-left: 40px;
    padding-right: 40px;

    .ContentHeader {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;

      .HeaderTitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        height: 48px;
        line-height: 48px;
        a,
        .Link {
          color: var(--primary-color);
        }
        .icon > div {
          display: flex;
          align-items: center;
          height: 48px;
        }
      }
      .HeaderToolBar {
        align-items: center;
        justify-content: center;
        display: flex;

        .ToolBarButtons {
          display: flex;
        }
      }
    }

    .ContentMain {
      background: #fff;
      width: 100%;
      border-radius: 8px;

      .ant-table-wrapper {
        .ant-table-content,
        .ant-table-container {
          .ant-table-body {
            &::-webkit-scrollbar {
              -webkit-appearance: none !important;
              background-color: transparent !important;
              width: 12px !important;
              height: 12px !important;
            }

            &::-webkit-scrollbar-thumb {
              background-clip: padding-box !important;
              background-color: #e5e5e5 !important;
              border-color: transparent !important;
              border-radius: 4px !important;
              border-style: solid !important;
              border-width: 4px !important;
            }

            &::-webkit-scrollbar-thumb:hover {
              background-color: rgba(0, 0, 0, 0.5) !important;
            }
          }
          .group,
          .user {
            padding: 4px 12px;
            background: #f4f4f4;
            border: 1px solid #e0e0e0;
            box-sizing: border-box;
            border-radius: 16px;
            margin: 0 2px;
          }
          .actions {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .ant-btn {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 5px;
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;
              height: 32px !important;

              .icon {
                height: 24px;
                margin-left: 5px;
                color: var(--primary-color);

                svg {
                  width: 24px;
                  height: 24px;
                }
              }

              &.edit {
                color: var(--primary-color);
              }

              &.delete {
                color: #d32f2f;
              }

              &.draft {
                background: #888888;
              }

              &.dispatched {
                background: #ffab00;
              }

              &.optimized,
              &.optimize {
                background: #d32f2f;
              }

              &.error {
                background: #f6d5d5;
                color: #d32f2f;
              }
              &.invite-user {
                color: #00c853;
                .icon {
                  svg path {
                    fill: #00c853;
                  }
                }
              }
              &.manage-users {
                color: #1e88e5;
                .icon {
                  svg path {
                    fill: #1e88e5;
                  }
                }
              }
            }
          }
        }
        .ant-pagination {
          margin: 16px 8px;

          li {
            &:first-child {
              flex: 1;
            }
            &:last-child {
              flex: 1;
              div {
                float: right;
              }
            }
          }

          .ant-pagination-item,
          .ant-pagination-prev,
          .ant-pagination-next {
            border: none;
            a {
              border: 1px solid var(--primary-color);
              border-radius: 2px;
            }
          }

          .ant-pagination-item:hover a,
          .ant-pagination-item-active a {
            background-color: var(--primary-color);
            color: #fff;
          }

          .ant-select-selector {
            border-radius: 4px;
            background: #ffffff;
            height: 32px !important;
            .ant-select-selection-item {
              margin-right: 10px;
              height: 32px !important;
              line-height: 32px !important;
            }
          }
        }
      }
    }
  }

  &.is-admin {
    background: #F4F4F4;
  }

  .BreadCrumb {
    margin: 24px 0;
    display: inline-block;
  
    a, span {
      color: #1d1d1b;
      text-transform: uppercase;
    }
  }


  .table-row-dark {
    background-color: #f4f4f4;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
      background-color: #f4f4f4;
    }
  }

  .table-row-dark:hover,
  .table-row-light:hover {
    background-color: transparent !important;
  }


  .status {
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-transform: capitalize;
    border-radius: 2px;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    max-width: 200px;
    min-width: 64px;


    &.active {
      background-color: #e6faee;
      color: #00c853;
    }

    &.inactive {
      background-color: #ffece8;
      color: #ff3d17;
    }
  }

  .ant-layout-header {
    padding: 0;
    background: var(--primary-color);
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f1f2f5;
    position: fixed;
    width: 100%;
    z-index: 999;
    height: 64px;
    line-height: 64px;
    align-items: center;

    .logo {
      padding-left: 40px;
      height: 64px;
      line-height: 64px;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      img {
        max-width: 141px;
        height: 32px;
      }

      span {
        margin-left: 10px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        padding: 4px 7px;
        background-color: #fff;
        color: #1e88e5;
        border-radius: 11px;
      }
    }

    .account-info {
      display: flex;
      padding: 0 10px;
      align-items: center;
      justify-content: center;

      .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        img {
          max-width: 32px;
          border-radius: 50%;
        }
      }
      .info {
        margin: auto 0;
        padding: 10px;
        .fullname {
          line-height: 16px;
          font-size: 12px;
          font-weight: 600;
          color: white;
        }
        .email {
          line-height: 16px;
          font-size: 10px;
          cursor: pointer;
          color: white;
        }
      }

      span {
        color: white;
        max-width: 10px;
        margin-left: 50px;
      }

      .ant-badge {
        margin: auto 10px;
        margin-right: -15px;
        .ant-badge-count {
          height: 24px;
          min-width: 24px;
          line-height: 24px;
          border-radius: 12px;
          .ant-scroll-number-only {
            height: 24px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.tags-input {
  min-height: 48px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px 15px 5px 15px;
  .tag-input {
    width: auto;
    flex-grow: 1;
    border: none;
    font-size: 16px;
    margin: 5px 0;
  }
  .edit-tag {
    border-radius: 5px;
    margin: 5px 8px 5px 0;
  }
}

.ant-modal-body {
  .FormHeader {
    line-height: 22px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      margin-left: -24px;
      border-left: 4px solid var(--primary-color);
      padding-left: 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #212121;
    }
    .right {
      a {
        display: flex;
        .icon > div {
          line-height: 24px;
          height: 24px;
          padding-left: 12px;
        }
      }
    }
  }

  .FormContent {
    label {
      font-weight: 600;
      font-size: 12px;
    }

    .item-label {
      display: flex;
      justify-content: space-between;
      padding-bottom: 8px;

      .right {
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #888888;
      }
    }
  }

  .FormFooter {
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: -24px;
    margin-right: -24px;
    gap: 12px;
    box-shadow: 0px -1px 0px #f4f4f4;

    .ant-btn {
      height: 48px !important;
      box-shadow: none;
      font-weight: 600;
    }

    .btn-i-got-this {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--primary-color);
      background: #ffffff;
      border: 1.5px solid var(--primary-color) !important;
      border-radius: 4px;

      .icon {
        height: 24px;
        margin-left: 20px;

        svg path {
          fill: var(--primary-color) !important;
        }
      }
    }

    .Left {
      .delete {
        border: 1px solid #e53935 !important;
      }
    }

    .Right {
      display: flex;
      .ant-btn {
        // min-width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon {
          height: 24px;
          margin-left: 20px;
        }
      }
    }
  }
}

.Layouts {
  position: relative;

  .add-to-home-screen {
    position: fixed;
    left: 0;
    right: 0;
    top: 10px;
    z-index: 10;

    .content {
      background-color: white;
      display: flex;
      align-items: center;
      max-width: 312px;
      margin: auto;
      padding: 10px;
      border-radius: 8px;

      p {
        font-size: 12px;
        font-weight: 350;
        line-height: 16px;
        margin: 0;
      }

      .ant-btn {
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
      }
    }
    
  }
}

.table-common {
  .ant-table-wrapper {
    padding: 0 8px;
  }

  .region-td,
  .capability-td {
    box-sizing: border-box;
    background: #f4f4f4;
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    width: max-content;
    padding: 0 8px;
    margin-top: 2px;
  }

  .capability-td {
    display: inline-block;
    margin-left: 4px;
  }

  td.ant-table-cell {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #1d1d1b;
  }

  th.ant-table-cell {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: #1d1d1b;
  }

  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    padding: 12px 24px;

    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        height: 32px;
        margin-left: 12px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 16px;
        font-weight: 600;
        font-size: 14px;
        padding: 0px 7px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;

        .count {
          font-size: 12px;
          line-height: 16px;
          color: white;
          font-weight: 700;
          background-color: var(--primary-color);
          border: 1px solid var(--primary-color);
          border-radius: 2px;
          padding: 0 4px;
          margin-right: 12px;
        }

        .icon {
          margin-left: 15px;
          margin-right: 2px;
        }
      }
    }
    .search {
      flex-basis: 624px;

      &.full {
        flex-basis: 100%;
      }
    }
  }
}

.FormContent {
  .ant-form-item {
    margin-bottom: 20px;

    .ant-col.ant-form-item-label {
      padding: 0 0 4px;

      label {
        font-size: 12px;
        font-weight: 350;
        line-height: 16px;
      }
    }

    .ant-radio-wrapper {
      margin-bottom: 8px;
      
      .ant-radio {
        align-self: flex-start;
      }
    }
  }
}

.ant-radio {
  .ant-radio-inner {
    width: 20px;
    height: 20px;
    border-color: #888888;
    border-width: 2px;
  }
  &.ant-radio-checked {
    .ant-radio-inner {
      border-color: var(--primary-color);
      &:after {
        background-color: var(--primary-color);
        transform: scale(0.55);
      }
    }
  }
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper {
  display: flex;
  flex-direction: row-reverse;
  max-height: 80px;

  p {
    margin: 0;
  }

  .ant-upload-list-item-container {
    margin: 0;
    height: 80px !important;
    width: 80px !important;
    width: auto;
  }

  .upload-text {
    font-size: 12px;
    line-height: 16px;
    color: #888888;
  }
  .upload-drag-icon {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding-top: 16px;
    color: var(--primary-color);
    .anticon {
      padding-left: 5px;
    }
  }
  .upload-supported {
    position: absolute;
    bottom: 2px;
    left: 2px;
    font-size: 12px;
    line-height: 16px;
    color: #888888;
  }
}

.page-service {
  .page {
    width: 21cm;
    min-height: 29.7cm;
    padding: 2cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

    h1 {
      width: 100%;
      margin: 0;
      text-align: center;
    }

    .page-contents {
      display: flex;
      flex-direction: column;
      gap: 16px;

      table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
      }

      th, td {
        padding: 10px;
      }

      .page-content {
        display: flex;

        &.sub-content {
          max-width: 100%;
        }
  
        .content-right {
          max-width: 100%;
        }
        > p, > b, > a {
          display: block;
        }
        p, b, a, u {
          margin: 0;
          font-size: 16px;
          line-height: 24px;
        }

        a {
          font-weight: bold;
        }

        .content-left {
          width: 40px;

          p, b {
            padding-right: 30px;
            display: block;
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .page {
      width: 100%;       /* Set to 100% for full width */
      padding: 1cm;      /* Adjust padding for smaller screens */
      margin: 0;         /* Remove margin for mobile */
      box-sizing: border-box; /* Ensures padding doesn't exceed screen width */
    }
  
    .page-contents {
      gap: 8px;          /* Smaller gap on mobile */
    }
  
    .page-content .content-left {
      width: auto;       /* Adjust left content width for better mobile layout */
    }
  }
}
