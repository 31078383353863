.CoachingMemberPopup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100vh;
  z-index: 1000;
  transition: opacity 0.3s ease;

  .popup-content {
    background: linear-gradient(180deg, #420000 0%, #DCB57B 100%);
    color: white;
    border-radius: 24px 24px 0px 0px;
    padding: 16px;
    text-align: center;
    width: 100%;
    animation: slide-up 0.3s ease-out;

    h2 {
      font-size: 24px;
      font-weight: 400;
      line-height: 40px;
      text-align: center;
      color: #FFFFFF;
      margin: 0 0 16px 0;
    }

    p {
      font-size: 14px;
      font-weight: 350;
      line-height: 20px;
      color: #FFFFFF;
      max-width: 206px;
      margin: 0 auto 16px auto;
    }
  }
  
  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .popup-image {
    max-width: 80px;
    margin: 0 auto 16px auto;
  
    img {
      border-radius: 50%;
      object-fit: cover;
    }
  }
  
  button {
    &:hover {
      background-color: #3e1212;
    }
  }
}