.SignInForm {
 padding: 16px;
 background: radial-gradient(67% 76% at 50% 50%, #E5E2CF 0%, #DCB57B 50%, #69140E 100%); /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
 border-radius: 16px;

 .FormContent {
  h1 {
    margin: 0;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 20px;
    color: #E5E2CF;
  }
 }
 .FormFooter {
  .ant-btn {
    height: 48px;
  }

  .ForgotPassword {
    margin-top: 24px;
    text-align: center;

    a {
      color: #E5E2CF;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }
 }
}