.CoachingInquiryPage {
  position: relative;
  padding-bottom: 76px;

  .header {
    padding: 36px 20px 12px 20px;
    box-shadow: 0px 1px 0px 0px #E0E0E0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content {
      display: flex;
      align-items: center;
      gap: 16px;

      .icon {
        > div {
          display: flex;
          align-items: center;
        }
      }
  
      p {
        margin: 0;
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        color: #E5E2CF;
      }
    }

    a {
      display: flex;
      align-items: center;
      gap: 4px;
      color: white;
    }
  }

  .body {

    .title {
      padding: 20px 0 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        margin: 0;
        color: #E5E2CF;
      }

      p {
        margin: 2px 0 0 0;
        color: white;
        font-size: 12px;
        font-weight: 350;
        line-height: 16px;
        text-align: left;

      }
    }
  
    .items-container {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      max-width: 600px;
      margin: 0 auto;
      padding: 0 20px;

      .item {
        padding: 8px 16px;
        border: 1px solid #E0E0E0;
        border-radius: 24px;
        background-color: transparent;
        // color: #888888;
        color: white;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-size: 16px;
        transition: all 0.3s ease;
        font-size: 12px;
        font-weight: 350;
        line-height: 16px;
        text-align: center;
  
      
        &.selected {
          background-color: #e7e3d4;
          color: #212121;
          font-weight: bold;
          padding-right: 8px;
      
          .checkmark {
            margin-left: 12px;
            color: #212121;
            max-width: 16px;
          }
        }
      }
      
      .checkmark {
        display: none;
      }
      
      .item.selected .checkmark {
        display: inline-block;
      }
    }

    .form-wrapper {
      background-color: #E5E2CF;
      padding: 0 20px;

      form {
        max-width: 400px;
        margin: auto;
        padding: 20px 20px 77px 20px;
      }

      .title {
        h2, p {
          margin: 0;
        }

        h2 {
          color: #212121;
        }

        p {
          color: #212121;
        }
      }

      .ant-radio {
        .ant-radio-inner {
          background-color: #E5E2CF;
        }
      }
    }

    .select-language {
      padding: 0 20px;

      .ant-radio-group {
        width: 100%;

        .ant-space  {
          width: 100%;

          .ant-radio-wrapper {
            padding: 12px 10px;
            border: 1.5px solid #E0E0E0;
            width: 100%;
            border-radius: 8px;

            span {
              font-size: 17px;
              font-weight: 350;
              line-height: 24px;
              color: white;
              text-align: left;
            }
    
            .ant-radio {
              .ant-radio-inner {
                background-color: transparent;
              }
            }

            &.ant-radio-wrapper-checked {
              background: linear-gradient(90deg, #DCB57B 0%, #E5E2CF 100%);
                
              span {
                color: #212121;
                font-weight: 400;
              }
            }
          }
        }
      }
    }

    .coaching-members {
      background: radial-gradient(52.13% 52.13% at 50% 50%, #E5E2CF 0%, #DCB57B 50%, #69140E 100%);
      min-height: calc(100vh - 128px);
      padding: 24px 16px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .card {
        background-color: white;
        padding: 16px;

        .card-header {
          display: flex;
          gap: 16px;
          align-items: center;

          .card-avatar {
            max-width: 80px;
          }

          .card-info {
            h3 {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color: #212121;
              margin: 0 0 3px 0;
            }

            p {
              font-size: 12px;
              font-weight: 350;
              line-height: 16px;
              color: #212121;
              margin: 0;
            }
          }
        }

        .card-content {
          p {
            font-size: 14px;
            font-weight: 350;
            line-height: 20px;
            color: #212121;
          }

          .tags {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            margin: 16px 0;

            .tag {
              background-color: #f0f0f0;
              color: #212121;
              padding: 0px 8px;
              border-radius: 16px;
              font-size: 14px;
              font-weight: 350;
              line-height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: background-color 0.3s ease;
            
              &:hover {
                background-color: #e0e0e0;
              }
            }
          }

        }
      }
    }
  }

  .footer {
    z-index: 2;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 16px 22px 16px;
    box-shadow: 0px 2px 4px #0000003D;

    p {
      margin: 0 0 12px 0;
      font-size: 14px;
      font-weight: 350;
      line-height: 24px;
      text-align: center;

      a {
        color: -var(--primary-color)
      }
    }
  }
}