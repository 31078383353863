.FormConfiguration {
  padding: 62px 50px;

  .wrapper {
    background-color: white;
    max-width: 600px;
    margin: auto;

    .pointer {
      cursor: pointer;
    }

    .header {
      font-size: 16px;
      line-height: 24px;
      padding: 24px 0;
      box-shadow: 0px 1px 0px 0px #E0E0E0;
  
      h4 {
        margin: 0;
        padding: 0 24px;
        border-left: 4px solid var(--primary-color);
      }
    }

    .body {
      .FormContent {
        padding: 24px;

        .form-item {
          padding: 20px 0;
          border-bottom: solid 1px #E0E0E0;
        }

        .btn-add {
          padding: 0;
          margin-top: 20px;
          margin-left: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: white;
          }

          .icon {
            margin-top: 12px;
          }

          .anticon {
            font-size: 10px;
            margin-top: 3px;
          }

          span:not(.anticon) {
            font-size: 12px;
            line-height: 16px;
            color: #212121;
          }

          &.ant-btn-lg {
            span:not(.anticon) {
              font-size: 16px;
              line-height: 24px;
            }

            .anticon {
              font-size: 20px;
              margin-top: 8px;
            }
          }
        }
      }

      .action {
        display: flex;
        align-items: center;
        gap: 12px;

        .ant-form-item {
          width: 95%;
        }

        .icon {
          max-width: 24px;
          display: inline-block;
          margin-top: 16px;
        }
      }
    }

    .footer {
      box-shadow: 0px -1px 0px 0px #E0E0E0;
      padding: 10px 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}