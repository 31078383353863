.RegisterPage {
  min-height: 100vh;
  position: relative;

  .header {
    max-width: 360px;
    margin: auto;
    display: flex;
    align-items: center;
    gap: 16px;
    padding-top: 36px;
    padding-left: 20px;

    .icon {
      > div {
        display: flex;
        align-items: center;
      }
      
    }

    p {
      margin: 0;
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      color: #E5E2CF;
    }
  }

  .body {
    min-height: calc(100vh - 80px);
    background-color: #E5E2CF;

    .content {
      background-color: #E5E2CF;
      max-width: 360px;
      margin: auto;
      padding: 20px 20px 77px 20px;

      .steps {
        display: flex;
        align-items: center;
        justify-content: center;

        .step {
          display: flex;
          align-items: center;

          .step-content {
            border: 1px solid #69140E;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            line-height: 16px;
            color: #69140E;
            background-color: white;
          }

          .line {
            background-color: #69140E;
            height: 1px;
            width: 108px;
          }

          &.wait {
            opacity: 0.5;
          }

          &.processing {
            .step-content {
              border: 1.5px solid #69140E;
            }
            .line {
              opacity: 0.5;
            }
          }

          &.finished {
            .step-content {
              background-color: #69140E;
              color: white
            }
          }
        }
      }

      .title {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin: 12px 0 20px 0;

        h2, p {
          margin: 0;
        }

        h2 {
          font-size: 12px;
          font-weight: 350;
          line-height: 16px;
        }

        p {
          font-size: 12px;
          font-weight: 350;
          line-height: 16px;
        }
      }

      .ant-radio {
        .ant-radio-inner {
          background-color: #E5E2CF;
        }
      }
    }
  }

  .footer {
    z-index: 2;
    position: fixed;
    background-color: #E5E2CF;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 16px 22px 16px;
    box-shadow: 0px 2px 4px #0000003D;

    .policy {
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      display: flex;
      gap: 5px
    }

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 350;
      line-height: 24px;
      text-align: center;
      max-width: 300px;

      a {
        color: #69140E;
        text-decoration: none;
      }
    }
  }
}