.UserPage {
  padding-bottom: 40px;

  .wrapper {
    background-color: white;
    max-width: 600px;
    margin: auto;

    &:not(:first-child) {
      margin-top: 20px;
    }

    .pointer {
      cursor: pointer;
    }

    .header {
      font-size: 16px;
      line-height: 24px;
      padding: 24px 0;
      box-shadow: 0px 1px 0px 0px #E0E0E0;
  
      h4 {
        margin: 0;
        padding: 0 24px;
        border-left: 4px solid var(--primary-color);
      }
    }

    .body {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      p, label {
        margin: 0;
        color: #212121;
      }

      label {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }

      .body-content {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }

    .footer {
      box-shadow: 0px -1px 0px 0px #E0E0E0;
      padding: 10px 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}